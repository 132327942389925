import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, Observable, catchError, throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  tokenNeededApi: string[] = [
    "/cart/fetch",
    "/customer/info",
    "/cart/add",
    "/cart/product/remove",
    "/ord/order/save",
    "/cat/cart/update",
    "/ord/custom-order",
    // "/cat/custom-order/",
    "/customer/update",
    "/product/catalog-share/get/list"
  ]

  constructor(
    private storage: StorageService,
  ) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let needToken = true;

    // for (let index = 0; index < this.tokenNeededApi.length; index++) {
    //   if(request.url.indexOf(this.tokenNeededApi[index]) > -1){
    //     needToken = true;
    //     break;
    //   }
    // }
    
    if (needToken) {
      let token = this.storage.getItem('accessToken');      
      // if(!token){
      //   return throwError(()=>new Error("Token not present"));
      // }
      request = this.addToken(request, token || "");
    }
    return next.handle(request)
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (token === "") return request;
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  

}

<div class="h-[100vh] flex flex-col justify-center items-center gap-10 flex-1 p-6">
    <div class="text-center p-3" >
        <img src="https://www.saleswizz.com/assets/footerLogo.svg" alt="">
    </div>
    <div class="h-[100vh] flex flex-col justify-center items-center gap-8" >
         <div class="text-8xl font-extrabold text-theme-primary-900">404</div>
        <div>
            <div class="m-0 mb-3">The page you are looking for does not exist</div>
           <div ></div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { BusinessDetailService } from './services/business-detail.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StorageService } from './user-service/storage.service';
import { filter, lastValueFrom, map, tap } from 'rxjs';
import { UserService } from './services/user.service';
import { SellerDetails } from './model/business.model';
import { environment } from '../environments/environment';
import { MetaTagService } from './services/meta-tag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  companyName: string = ''
  collectionId: string = ''
  orderType:string='';
  sellerDetails: SellerDetails|undefined;

  imageBase = environment.imageBaseUrl;

  constructor(
    private businessDetail: BusinessDetailService,
    private storage: StorageService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private metatag: MetaTagService
  ) {

    // subscribe to changes in sellerDetail
    businessDetail.sellerDetails.subscribe(data=>{
      if(!data) return;
      this.sellerDetails = data;
      if(data && !data.isExpired){
        this.businessDetail.getCartCount();
        this.userService.fetchUserData();
      }
    })

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(()=>{
        let child = this.route.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data) {
            return child.snapshot.data;
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any)=>{
      this.metatag.setTitle(data.title);
      this.metatag.setMetaTag("description", data.description)
      this.metatag.setMetaTag("og:title", this.sellerDetails?.companyName || data.title)
      this.metatag.setMetaTag("og:description", this.sellerDetails?.description || data.description)
      this.metatag.setMetaTag("og:image", this.imageBase + (this.sellerDetails?.companyLogoId||"assets/images/salesWizz.png"))
      this.metatag.setMetaTag("twitter:title", this.sellerDetails?.companyName || data.title)
      this.metatag.setMetaTag("twitter:description", this.sellerDetails?.description || data.description)
      this.metatag.setMetaTag("twitter:image", this.imageBase + (this.sellerDetails?.companyLogoId||"assets/images/salesWizz.png"))      
    })
  }

  async ngOnInit() {
    this.companyName = this.storage.getItem('comapanyName') || '';
    this.collectionId = this.storage.getItem('catalogueId') || '';
    this.orderType = this.storage.getItem('templateType') || '';

    if (!this.businessDetail.sellerDetails.value) {
      this.businessDetail.getCatalogMetadata(this.companyName, this.collectionId, this.orderType);
    }
  }
  
}
